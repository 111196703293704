import Image from 'next/image'
import Link from 'next/link'
import React from 'react'
import Meta from '../components/Meta'
import gradientImg from '../public/images/gradient_light.jpg'
import Config from '../site.config'

const Error_page = () => {
  return (
    <div>
      <Meta title={`404 || ${Config.site.name}`} />
      <div className='pt-[5.5rem] lg:pt-24'>
        {/* <!-- 404 --> */}
        <section className='dark:bg-jacarta-800 relative py-16 md:py-24'>
          <picture className='pointer-events-none absolute inset-0 -z-10 dark:hidden'>
            <Image
              src={gradientImg}
              alt='gradient'
              priority={true}
              width={1920}
              height={789}
            />
          </picture>

          <div className='container'>
            <div className='mx-auto max-w-lg text-center'>
              <span className='mb-14 inline-block'>
                <Image
                  src='/images/404.png'
                  width={336}
                  height={165}
                  alt='gradient'
                />
              </span>
              <h1 className='text-jacarta-700 font-display mb-6 text-4xl dark:text-white md:text-6xl'>
                Página no encontrada!
              </h1>
              <p className='dark:text-jacarta-300 mb-12 text-lg leading-normal'>
                Oops! Parece que la página que buscas no existe. Podría haberse
                borrado o movido. Intenta buscando <Link href='/'>aquí</Link>.
              </p>
            </div>
          </div>
        </section>
        {/* <!-- end 404 --> */}
      </div>
    </div>
  )
}

export default Error_page
